import React from 'react';
import { Product } from 'shared/types/product/Product';
import Container from 'components/commercetools-ui/atoms/container';
import Wrapper from 'components/HOC/wrapper';
import CurrentRefinements from './components/current-refinements';
import DesktopFacets from './components/desktop-facets';
import List from './components/list';
import MobileFacets from './components/mobile-facets';
import Pagination from './components/pagination';
import SearchHeader from './components/search-header';
import Sort from './components/sort';
import { useProductList } from './context';

export interface Props {
  products: Product[];
}

const ProductList: React.FC<Props> = ({ products }) => {
  const { searchQuery, facetsConfiguration, totalItems } = useProductList();

  return (
    <div className="min-h-screen bg-background pb-32 lg:pb-40">
      <Container>
        <Wrapper background="background" className="pt-32 lg:pt-17">
          <SearchHeader query={searchQuery ?? ''} />
        </Wrapper>

        {totalItems !== 0 ? (
          <div className="my-24 flex flex-row gap-20 px-0 md:px-24 lg:my-32 lg:px-20 xl:px-48">
            <div className="hidden w-3/12 pb-12 pt-32 lg:block">
              <DesktopFacets facetsConfiguration={facetsConfiguration} />
            </div>
            <div className="flex h-fit w-full flex-col gap-24 bg-neutral-5 pb-32 pt-16 lg:w-9/12 lg:pt-24">
              <div className="w-full flex-1 justify-between px-16 md:px-0 lg:flex lg:flex-row-reverse lg:gap-40 lg:px-12">
                <div className="flex flex-row justify-between max-lg:pb-12">
                  <MobileFacets facetsConfiguration={facetsConfiguration} />
                  <div className="ml-auto min-w-fit">
                    <Sort />
                  </div>
                </div>
                <div className="max-lg:shrink-0 max-lg:grow-0 max-lg:basis-full">
                  <CurrentRefinements />
                </div>
              </div>
              <List products={products} />
              <Pagination />
            </div>
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default ProductList;
