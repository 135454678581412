import { useMemo, useState } from 'react';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import Button from 'components/commercetools-ui/atoms/button';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import FilterIcon from 'components/icons/FilterIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProductList } from '../../context';
import useDynamicFacets from '../../hooks/useDynamicFacets';
import { FacetConfiguration } from '../../types';
import SortFacet from '../facets/sort';

interface Props {
  facetsConfiguration: Record<string, FacetConfiguration>;
}

const MobileFacets: React.FC<Props> = ({ facetsConfiguration }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const attributeMapping = useMemo<Record<string, string>>(
    () => ({
      Category: formatProductMessage({ id: 'categoryFacet', defaultMessage: 'Category' }),
      Price: formatProductMessage({ id: 'priceFacet', defaultMessage: 'Price' }),
      Brand: formatProductMessage({ id: 'brandFacet', defaultMessage: 'Brand' }),
      status: formatProductMessage({ id: 'statusFacet', defaultMessage: 'Status' }),
    }),
    [formatProductMessage],
  );

  const { removeAllRefinements, totalItems } = useProductList();

  const [isOpen, setIsOpen] = useState(false);

  const accordionClassNames = useMemo(
    () => ({
      root: 'mx-20 border-b border-b-neutral-2 first:mt-24',
      button: 'text-neutral-4',
      buttonWrapper: 'mb-16 text-left',
    }),
    [],
  );

  const facets = useDynamicFacets({
    configuration: facetsConfiguration,
    render: ({ attribute, Component }) => (
      <Accordion
        key={attribute}
        className={accordionClassNames.root}
        buttonClassName={accordionClassNames.button}
        buttonWrapperClassName={accordionClassNames.buttonWrapper}
        closedSectionTitle={attributeMapping[attribute] ?? attribute}
        variant="arrow-filter"
      >
        <div className="mb-24 mt-8">{Component}</div>
      </Accordion>
    ),
  });

  const sortFacet = useMemo(
    () => (
      <Accordion
        className={accordionClassNames.root}
        buttonClassName={accordionClassNames.button}
        buttonWrapperClassName={accordionClassNames.buttonWrapper}
        closedSectionTitle={formatProductMessage({ id: 'sortBy', defaultMessage: 'Sort by' })}
        variant="arrow-filter"
      >
        <div className="mb-24 mt-32">
          <SortFacet />
        </div>
      </Accordion>
    ),
    [formatProductMessage, accordionClassNames],
  );

  return (
    <>
      <div className="flex items-center justify-between lg:hidden">
        <button className="flex cursor-pointer items-center gap-8" onClick={() => setIsOpen(true)}>
          <FilterIcon />
          <span className="font-bold">
            {formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Filter & Sort' })}
          </span>
        </button>
      </div>
      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        direction="left"
        className="w-[90%] max-w-[400px] bg-white"
      >
        {/* Header */}
        <div className="relative border-b border-neutral-400 px-12 py-16">
          <h3 className="text-center text-18">
            {formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Filter & Sort' })}
          </h3>
          <span className="absolute right-14 top-18 h-24 w-24 cursor-pointer" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </span>
        </div>
        {/* Body */}
        <div className="flex grow flex-col gap-16 overflow-auto">
          {facets}
          {sortFacet}
        </div>
        {/* Footer */}
        <div className="shadow-custom-2 flex w-full flex-col gap-16 bg-white px-20 py-16">
          <div className="text-center text-14 text-neutral-4">
            {totalItems} {formatProductMessage({ id: 'products', defaultMessage: 'Products' })}
          </div>
          <div className="flex items-center gap-12">
            <Button
              variant="secondary"
              onClick={() => {
                removeAllRefinements();
                setIsOpen(false);
              }}
            >
              {formatProductMessage({ id: 'clear.all', defaultMessage: 'Clear All' })}
            </Button>
            <Button variant="primary" onClick={() => setIsOpen(false)} className="flex-1">
              {formatProductMessage({ id: 'done', defaultMessage: 'Done' })}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default MobileFacets;
