import { useEffect, useMemo, useRef, useState, type JSX } from 'react';
import { useParams, useSearchParams } from 'next/navigation';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useI18n from 'helpers/hooks/useI18n';
import MultiSelectFacet from '../components/facets/multiSelect';
import SliderFacet from '../components/facets/slider';
import TreeFacet from '../components/facets/tree';
import { FacetProps } from '../components/facets/types';
import { FacetConfiguration, FacetFilterStyle } from '../types';

interface Options {
  configuration?: Record<string, FacetConfiguration>;
  render?: (result: {
    attribute: string;
    hits?: string | number;
    isLoading: { [key: string]: boolean };
    Component: JSX.Element;
  }) => JSX.Element;
}

const useDynamicFacets = ({ configuration, render }: Options = {}) => {
  const { locale } = useParams();
  const { currency } = useI18n();
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});
  const loadingState = useMemo(() => ({ value: isLoading, set: setIsLoading }), [isLoading]);

  const searchParams = useSearchParams();
  const prevSearchParamsRef = useRef(searchParams.toString());
  useEffect(() => {
    const currentSearchParams = searchParams.toString();
    if (currentSearchParams !== prevSearchParamsRef.current) {
      setIsLoading({});
      prevSearchParamsRef.current = currentSearchParams;
    }
  }, [searchParams]);

  const facetMapping = useMemo<Record<FacetConfiguration['type'], React.ComponentType<FacetProps>>>(
    () => ({
      TREE: TreeFacet,
      MULTISELECT: MultiSelectFacet,
      SLIDER: SliderFacet,
    }),
    [],
  );

  const dynamicFacets = useMemo(() => {
    if (!configuration) return <></>;

    const facets = Object.keys(configuration).map((attribute) => {
      const facet = configuration[attribute];

      let hits;

      switch (facet.filterStyle) {
        case FacetFilterStyle.TREE:
          hits = facet.selectedElements.length !== 0 ? 1 : undefined;
          break;
        case FacetFilterStyle.MULTISELECT:
          hits = facet.selectedElements.length;
          break;
        case FacetFilterStyle.SLIDER:
          const selectedElement = facet.selectedElements[0];
          hits = selectedElement
            ? `${CurrencyHelpers.formatForCurrency(
                selectedElement.selectedMinValue * 100,
                locale,
                currency,
                2,
              )} - ${CurrencyHelpers.formatForCurrency(selectedElement.selectedMaxValue * 100, locale, currency, 2)}`
            : undefined;
          break;
        default:
          hits = undefined;
      }

      const Component = facetMapping[facet.filterStyle];
      const FinalComponent = <Component key={attribute} attribute={attribute} loadingState={loadingState} />;

      return {
        attribute,
        hits,
        isLoading,
        Component: render?.({ attribute, hits, isLoading, Component: FinalComponent }) ?? FinalComponent,
      };
    });

    return facets.map((facet) => facet.Component);
  }, [configuration, currency, facetMapping, isLoading, loadingState, locale, render]);

  return dynamicFacets;
};

export default useDynamicFacets;
