'use client';

import React from 'react';
import { useSearchParams } from 'next/navigation';
import { Product } from 'shared/types/product/Product';
import ProductList, {
  Props as ProductListProps,
} from 'components/commercetools-ui/organisms/product/product-list-fact-finder';
import ProductListProvider from 'components/commercetools-ui/organisms/product/product-list-fact-finder/context';
import { FacetConfiguration } from 'components/commercetools-ui/organisms/product/product-list-fact-finder/types';
import { DataSource } from 'types/datasource';
import { TasticProps } from 'frontastic/tastics/types';
import useFacetsConfiguration from './hooks/useFacetsConfiguration';

interface DataSourceProps {
  items: Product[];
  facets: FacetConfiguration[];
  total: number;
}

const ProductListWrapped = ({ data }: TasticProps<DataSource<DataSourceProps> & ProductListProps>) => {
  return <ProductList products={data?.data?.dataSource?.items ?? []} />;
};

const ProductListFactFinderTastic = ({
  data,
  ...props
}: TasticProps<DataSource<DataSourceProps> & ProductListProps>) => {
  const searchParams = useSearchParams();

  const searchQuery = searchParams.get('query') as string;

  const facetsConfiguration = useFacetsConfiguration({ facetsConfiguration: data?.data?.dataSource?.facets });

  return (
    <ProductListProvider
      uiState={{
        searchQuery,
        totalItems: data?.data?.dataSource?.total ?? 0,
      }}
      facetsConfiguration={facetsConfiguration}
    >
      <ProductListWrapped data={data} {...props} />
    </ProductListProvider>
  );
};

export default ProductListFactFinderTastic;
