import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import cloneDeep from 'lodash/cloneDeep';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useI18n from 'helpers/hooks/useI18n';
import usePath from 'helpers/hooks/usePath';
import { ActiveRefinement, ProductListContextShape, Sort, UiState } from './types';
import {
  FacetConfiguration,
  FacetElement,
  FacetElementSelected,
  FacetFilterStyle,
  MultiSelectFacetProps,
  SliderFacetProps,
  TreeFacetProps,
} from '../types';

export const ProductListContext = createContext<ProductListContextShape>({
  facetsConfiguration: {},
  totalItems: 0,
  activeRefinements: [],
  firstPage: 1,
  lastPage: 1,
  currentPage: 1,
  nextPage: 1,
  previousPage: 1,
  refine() {},
  refineSlider() {},
  replaceSort() {},
  removeAllRefinements() {},
});

export interface ProductListPropsProps {
  uiState: UiState;
  facetsConfiguration: Record<string, FacetConfiguration>;
}

const ProductListProvider = ({
  children,
  uiState,
  facetsConfiguration,
}: React.PropsWithChildren<ProductListPropsProps>) => {
  const router = useRouter();
  const { pathWithoutQuery } = usePath();
  const searchParams = useSearchParams();
  const { locale } = useParams();
  const { currency } = useI18n();

  const activeSort = useMemo<Sort | undefined>(() => {
    let result;
    searchParams.forEach((value, key) => {
      if (key.includes('sortAttributes')) {
        const match = key.match(/sortAttributes\[0\]\[(.+)\]/);
        if (match?.[1]) result = { attribute: match[1], value: value as 'asc' | 'desc' };
      }
    });
    return result;
  }, [searchParams]);

  const limit = searchParams.get('limit');
  const totalProducts = uiState.totalItems;
  const productsPerPage = limit ? Number(limit) : 24;
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const firstPage = 1;
  const lastPage = totalPages;
  const pageFromParams = searchParams.get('page');
  let currentPage = firstPage;
  if (pageFromParams) {
    const page = +pageFromParams;
    if (page <= firstPage) {
      currentPage = firstPage;
    } else if (page >= lastPage) {
      currentPage = lastPage;
    } else {
      currentPage = page;
    }
  }
  const nextPage = Math.min(currentPage + 1, lastPage);
  const previousPage = Math.max(currentPage - 1, firstPage);

  const applyRefinements = useCallback(
    (facetsConfiguration: Record<string, FacetConfiguration>, sort?: Sort) => {
      const params = new URLSearchParams();

      if (uiState?.searchQuery) {
        params.set('query', uiState.searchQuery);
      }

      Object.values(facetsConfiguration).forEach((facet) => {
        if (facet.filterStyle === FacetFilterStyle.TREE) {
          facet.selectedElements
            .filter((element) => element.selected === FacetElementSelected.TRUE && element.clusterLevel === 0)
            .forEach((element) => params.append('filter', `${facet.name}:${element.text}`));
        } else if (facet.filterStyle === FacetFilterStyle.MULTISELECT) {
          facet.selectedElements
            .filter((element) => element.selected === FacetElementSelected.TRUE)
            .forEach((element) => params.append('filter', `${facet.name}:${element.text}`));
        } else {
          facet.selectedElements
            .filter((element) => element.selected === FacetElementSelected.TRUE)
            .forEach((element) =>
              params.append('filter', `${facet.name}:[${element.selectedMinValue},${element.selectedMaxValue}]`),
            );
        }
      });

      if (sort) params.set(`sortAttributes[0][${sort.attribute}]`, sort.value);

      router.push(`${pathWithoutQuery}?${params.toString()}`, { scroll: false });
    },
    [uiState.searchQuery, router, pathWithoutQuery],
  );

  const activeRefinements = useMemo(() => {
    const refinements = [] as ActiveRefinement[];

    const newFacetsConfiguration = cloneDeep(facetsConfiguration);

    const addRefinement = (configuration: FacetConfiguration, label: string, refineCb: () => void) => {
      refinements.push({
        attribute: configuration.name,
        label,
        refine() {
          refineCb();

          applyRefinements(newFacetsConfiguration, activeSort);
        },
      });
    };

    Object.values(newFacetsConfiguration).forEach((facet) => {
      if (facet.filterStyle === FacetFilterStyle.TREE) {
        facet.selectedElements
          .filter((element) => element.selected === FacetElementSelected.TRUE && element.clusterLevel === 0)
          .forEach((element) =>
            addRefinement(facet, element.text, () => {
              const newElement = { ...element, selected: FacetElementSelected.FALSE };
              facet.elements.push(newElement);
              facet.selectedElements = [];
            }),
          );
      } else if (facet.filterStyle === FacetFilterStyle.MULTISELECT) {
        facet.selectedElements
          .filter((element) => element.selected === FacetElementSelected.TRUE)
          .forEach((element) =>
            addRefinement(facet, element.text, () => {
              const newElement = { ...element, selected: FacetElementSelected.FALSE };
              facet.elements.push(newElement);
              facet.selectedElements = facet.selectedElements.filter((x) => x.text !== element.text);
            }),
          );
      } else {
        facet.selectedElements
          .filter((element) => element.selected === FacetElementSelected.TRUE)
          .forEach((element) => {
            const label = `${CurrencyHelpers.formatForCurrency(
              element.selectedMinValue * 100,
              locale,
              currency,
              2,
            )} - ${CurrencyHelpers.formatForCurrency(element.selectedMaxValue * 100, locale, currency, 2)}`;

            addRefinement(facet, label, () => {
              const newElement = {
                ...element,
                selected: FacetElementSelected.FALSE,
                selectedMinValue: element.absoluteMinValue,
                selectedMaxValue: element.absoluteMaxValue,
              };
              facet.elements.push(newElement);
              facet.selectedElements = [];
            });
          });
      }
    });

    return refinements;
  }, [facetsConfiguration, applyRefinements, activeSort, locale, currency]);

  const replaceSort = useCallback(
    (newSort: Sort) => {
      applyRefinements(facetsConfiguration, newSort);
    },
    [facetsConfiguration, applyRefinements],
  );

  const refine = useCallback(
    (attribute: string, element: FacetElement) => {
      const newFacetsConfiguration = cloneDeep(facetsConfiguration);

      const facet = newFacetsConfiguration[attribute] as TreeFacetProps | MultiSelectFacetProps;

      const isSelected = element.selected === FacetElementSelected.FALSE ? false : true;
      const newElement = { ...element, selected: isSelected ? FacetElementSelected.FALSE : FacetElementSelected.TRUE };

      if (facet.filterStyle === FacetFilterStyle.TREE) {
        if (isSelected) {
          facet.elements.push(newElement);
          facet.selectedElements = [];
        } else {
          facet.elements = [];
          facet.selectedElements.push(newElement);
        }
      } else {
        if (isSelected) {
          facet.elements.push(newElement);
          facet.selectedElements = facet.selectedElements.filter((x) => x.text !== element.text);
        } else {
          facet.elements = facet.elements.filter((x) => x.text !== element.text);
          facet.selectedElements.push(newElement);
        }
      }

      applyRefinements(newFacetsConfiguration, activeSort);
    },
    [facetsConfiguration, applyRefinements, activeSort],
  );

  const refineSlider = useCallback(
    (attribute: string, value: { min: number; max: number }) => {
      const newFacetsConfiguration = cloneDeep(facetsConfiguration);

      const facet = newFacetsConfiguration[attribute] as SliderFacetProps;
      const selectedElement = facet.selectedElements.find((element) => element.selected === FacetElementSelected.TRUE);

      if (selectedElement) {
        const newElement = { ...selectedElement, selectedMinValue: value.min, selectedMaxValue: value.max };

        facet.selectedElements = [newElement];
      } else {
        const newElement = {
          ...facet.elements[0],
          selected: FacetElementSelected.TRUE,
          selectedMinValue: value.min,
          selectedMaxValue: value.max,
        };

        facet.elements = [];
        facet.selectedElements = [newElement];
      }

      applyRefinements(newFacetsConfiguration, activeSort);
    },
    [facetsConfiguration, applyRefinements, activeSort],
  );

  const removeAllRefinements = useCallback(() => {
    const newFacetsConfiguration = cloneDeep(facetsConfiguration);

    Object.values(newFacetsConfiguration).forEach((facet) => {
      facet.selectedElements = [];
    });

    applyRefinements(newFacetsConfiguration, activeSort);
  }, [applyRefinements, facetsConfiguration, activeSort]);

  return (
    <ProductListContext.Provider
      value={{
        ...uiState,
        facetsConfiguration,
        activeSort,
        activeRefinements,
        firstPage,
        lastPage,
        currentPage,
        nextPage,
        previousPage,
        refine,
        refineSlider,
        replaceSort,
        removeAllRefinements,
      }}
    >
      {children}
    </ProductListContext.Provider>
  );
};

export default ProductListProvider;

export const useProductList = () => useContext(ProductListContext);
