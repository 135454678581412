import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { Menu } from '@headlessui/react';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProductList } from '../../context';
import SortFacet from '../facets/sort';

const Sort: React.FC = () => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { totalItems } = useProductList();

  // BEGIN control loading state
  // Control the loading state for Facets and the Sort button
  // When query params update it means the new content has loaded
  const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});
  const isLoadingState = useMemo(() => ({ value: isLoading, set: setIsLoading }), [isLoading]);

  const prevSearchParamsRef = useRef(searchParams.toString());

  useEffect(() => {
    const currentSearchParams = searchParams.toString();
    if (currentSearchParams !== prevSearchParamsRef.current) {
      setIsLoading({});
      prevSearchParamsRef.current = currentSearchParams;
    }
  }, [searchParams]);
  // END control loading state

  const sortFacet = useMemo(
    () => (
      <Menu as="div" className="relative">
        {({ open, close }) => {
          const label = 'sort';

          return (
            <>
              <Menu.Button disabled={isLoading[label]}>
                <div className="flex items-center rounded-full border border-neutral-4 bg-white px-12 py-8">
                  {isLoading[label] ? (
                    <LoadingIcon className="fill-gray-700" />
                  ) : (
                    <>
                      <span className="text-14 text-neutral-4">
                        {formatProductMessage({ id: 'sortBy', defaultMessage: 'Sort by' })}
                      </span>
                      <ChevronDownIcon />
                    </>
                  )}
                </div>
              </Menu.Button>
              <Menu.Items
                static
                className={`shadow-custom absolute right-0 origin-top-right translate-y-[10px] rounded-xl border border-neutral-4 bg-white py-12 shadow-lg transition ${
                  open ? 'z-20 scale-100' : 'z-[-1] scale-95 opacity-0'
                }`}
              >
                <Menu.Item as="div">
                  <SortFacet close={close} isLoadingState={isLoadingState} label={label} />
                </Menu.Item>
              </Menu.Items>
            </>
          );
        }}
      </Menu>
    ),
    [formatProductMessage, isLoading, isLoadingState],
  );

  return (
    <div className="flex items-center gap-20 whitespace-nowrap">
      <span className="text-14 leading-[17.5px] text-neutral-4">
        {totalItems} {formatProductMessage({ id: 'items', defaultMessage: 'Items' })}
      </span>
      <div>{sortFacet}</div>
    </div>
  );
};

export default Sort;
