import { useMemo } from 'react';
import { FacetConfiguration } from 'components/commercetools-ui/organisms/product/product-list-fact-finder/types';

interface Config {
  facetsConfiguration: FacetConfiguration[] | undefined;
}

const useFacetsConfiguration = ({ facetsConfiguration }: Config) => {
  const finalFacetsConfiguration = useMemo<Record<string, FacetConfiguration>>(() => {
    return (facetsConfiguration ?? []).reduce(
      (acc, configuration) => ({
        ...acc,
        [configuration.associatedFieldName]: {
          name: configuration.name,
          elements: configuration.elements,
          selectedElements: configuration.selectedElements,
          type: configuration.type,
          filterStyle: configuration.filterStyle,
          selectionType: configuration.selectionType,
        } as FacetConfiguration,
      }),
      {},
    );
  }, [facetsConfiguration]);

  return finalFacetsConfiguration;
};

export default useFacetsConfiguration;
