import { useMemo } from 'react';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import { useFormat } from 'helpers/hooks/useFormat';
import useDynamicFacets from '../../hooks/useDynamicFacets';
import { FacetConfiguration } from '../../types';

interface Props {
  facetsConfiguration: Record<string, FacetConfiguration>;
}

const DesktopFacets: React.FC<Props> = ({ facetsConfiguration }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const mainFacets = useMemo(() => ['Category', 'Price', 'Brand', 'status'], []);
  const attributeMapping = useMemo<Record<string, string>>(
    () => ({
      Category: formatProductMessage({ id: 'categoryFacet', defaultMessage: 'Category' }),
      Price: formatProductMessage({ id: 'priceFacet', defaultMessage: 'Price' }),
      Brand: formatProductMessage({ id: 'brandFacet', defaultMessage: 'Brand' }),
      status: formatProductMessage({ id: 'statusFacet', defaultMessage: 'Status' }),
    }),
    [formatProductMessage],
  );

  const facets = useDynamicFacets({
    configuration: facetsConfiguration,
    render: ({ attribute, hits, isLoading, Component }) => (
      <Accordion
        key={attribute}
        variant="facet"
        defaultOpen={mainFacets.includes(attribute)}
        closedSectionTitle={attributeMapping[attribute] ?? attribute}
        facetHits={hits}
        loading={isLoading[attribute]}
        buttonClassName="font-bold text-16 leading-[20px] text-left text-neutral-4"
        buttonWrapperClassName="pb-12"
      >
        {Component}
      </Accordion>
    ),
  });

  return <div className="flex grow flex-col gap-24 overflow-auto pb-1 pl-1">{facets}</div>;
};

export default DesktopFacets;
