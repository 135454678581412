export enum FacetElementSelected {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  IMPLICIT = 'IMPLICIT',
  IRRELEVANT = 'IRRELEVANT',
}

export interface FacetElement {
  absoluteMaxValue: number;
  absoluteMinValue: number;
  clusterLevel: number;
  selected: FacetElementSelected;
  selectedMaxValue: number;
  selectedMinValue: number;
  text: string;
  totalHits: number;
}

export interface Facet {
  name: string;
  elements: Array<FacetElement>;
  selectedElements: Array<FacetElement>;
  type: string;
  filterStyle: string;
  selectionType: string;
  associatedFieldName: string;
}

export enum FacetFilterStyle {
  TREE = 'TREE',
  SLIDER = 'SLIDER',
  MULTISELECT = 'MULTISELECT',
}

export interface TreeFacetProps extends Omit<Facet, 'filterStyle'> {
  filterStyle: FacetFilterStyle.TREE;
}

export interface SliderFacetProps extends Omit<Facet, 'filterStyle'> {
  filterStyle: FacetFilterStyle.SLIDER;
}

export interface MultiSelectFacetProps extends Omit<Facet, 'filterStyle'> {
  filterStyle: FacetFilterStyle.MULTISELECT;
}

export type FacetConfiguration = TreeFacetProps | SliderFacetProps | MultiSelectFacetProps;
