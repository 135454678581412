import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from 'components/commercetools-ui/atoms/input';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import { FacetProps } from './types';
import { useProductList } from '../../context';
import { FacetElement, FacetElementSelected, SliderFacetProps } from '../../types';

const SliderFacet: React.FC<FacetProps> = ({ attribute, loadingState }) => {
  const { facetsConfiguration, refineSlider } = useProductList();
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { currencySymbol } = useI18n();

  const facet = useMemo(() => facetsConfiguration[attribute] as SliderFacetProps, [facetsConfiguration, attribute]);

  const selectedElement = facet.selectedElements.find((element) => element.selected === FacetElementSelected.TRUE);
  const element = selectedElement ? selectedElement : facet.elements[0];

  const sliderState = useMemo(
    () => ({
      min: element.selected === FacetElementSelected.FALSE ? element.absoluteMinValue : element.selectedMinValue,
      max: element.selected === FacetElementSelected.FALSE ? element.absoluteMaxValue : element.selectedMaxValue,
    }),
    [element],
  );

  const [slider, setSlider] = useState(sliderState);

  useEffect(() => {
    setSlider(sliderState);
  }, [sliderState]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSlider({ ...slider, [e.target.name]: +e.target.value });
    },
    [slider],
  );

  const onBlur = (element: FacetElement) => {
    if (element.selectedMinValue !== slider.min || element.selectedMaxValue !== slider.max) {
      if (loadingState) {
        loadingState.set({ [attribute]: true });
      }

      refineSlider(attribute, slider);
    }
  };

  return (
    <div className="flex flex-row gap-12 pb-20 pt-4">
      <div className="w-full">
        <label htmlFor="min" className="relative flex" aria-label="min">
          <Input
            variant="primary"
            wrapperClassName="flex items-center"
            hideCheckIcon={true}
            id="min"
            name="min"
            className="pr-45"
            type="number"
            value={slider.min.toString()}
            placeholder={formatProductMessage({ id: 'min', defaultMessage: 'Min' })}
            onChange={onChange}
            onBlur={() => onBlur(element)}
          />
          <div className="absolute right-0 flex h-full w-35 items-center justify-center rounded-r-[48px] border border-neutral-2 bg-neutral-0">
            <span className="text-14 font-bold leading-[17.5px] text-neutral-4">{currencySymbol}</span>
          </div>
        </label>
      </div>
      <div className="w-full">
        <label htmlFor="max" className="relative flex" aria-label="max">
          <Input
            variant="primary"
            wrapperClassName="flex items-center"
            hideCheckIcon={true}
            id="max"
            name="max"
            className="pr-45"
            type="number"
            value={slider.max.toString()}
            placeholder={formatProductMessage({ id: 'max', defaultMessage: 'Max' })}
            onChange={onChange}
            onBlur={() => onBlur(element)}
          />
          <div className="absolute right-0 flex h-full w-35 items-center justify-center rounded-r-[48px] border border-neutral-2 bg-neutral-0">
            <span className="text-14 font-bold leading-[17.5px] text-neutral-4">{currencySymbol}</span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default SliderFacet;
