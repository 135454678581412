import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import CloseIcon from 'components/icons/CloseIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProductList } from '../../context';
import useRefinementHelpers from '../../hooks/useRefinementHelpers';

const CurrentRefinements = () => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { removeAllRefinements, activeRefinements } = useProductList();
  const { resolveLabel } = useRefinementHelpers();

  // BEGIN control loading state
  // Control the loading state for active filters and the Clear All button
  // When query params update it means the new content has loaded
  const searchParams = useSearchParams();
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});

  const prevSearchParamsRef = useRef(searchParams.toString());
  useEffect(() => {
    const currentSearchParams = searchParams.toString();
    if (currentSearchParams !== prevSearchParamsRef.current) {
      setIsLoading({});
      prevSearchParamsRef.current = currentSearchParams;
    }
  }, [searchParams]);
  // END control loading state

  const clearAllLabel = 'clearAll';

  const handleOnClick = (label: string, callback: () => void) => {
    // If already loading, do nothing (simulate "disabled" state because this is a div, not a button)
    if (isLoading[label]) return;

    setIsLoading((prev) => ({ ...prev, [label]: true }));
    callback();
  };

  if (!activeRefinements.length) return <></>;

  return (
    <>
      <div className="flex flex-wrap items-center justify-start gap-12 py-4">
        {/* List of active refinements */}
        {activeRefinements.map(({ attribute, label, refine }) => (
          <div
            key={label}
            onClick={() => handleOnClick(label, refine)}
            className="group flex cursor-pointer items-center rounded-full border border-base-accent-3 bg-base-accent-3 px-12 py-7 text-neutral-4 hover:border-base-accent-2 hover:bg-base-accent-2"
          >
            {isLoading[label] ? (
              <LoadingIcon className="fill-gray-700" />
            ) : (
              <>
                <span className="pr-4 text-14 leading-[17.5px]">{resolveLabel(attribute, label)}</span>
                <div className="group-hover:rounded-full group-hover:bg-base-accent-3 group-hover:text-base-accent-link">
                  <CloseIcon scale={0.84} />
                </div>
              </>
            )}
          </div>
        ))}

        <div
          className="flex cursor-pointer items-center justify-center gap-4 text-neutral-6"
          onClick={() => handleOnClick(clearAllLabel, removeAllRefinements)}
        >
          {isLoading[clearAllLabel] ? (
            <LoadingIcon className="fill-gray-700" />
          ) : (
            <>
              <span className="pr-4 text-14 leading-[17.5px]">
                {formatProductMessage({ id: 'clear.all', defaultMessage: 'Clear All' })}
              </span>
              <CloseIcon scale={0.84} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentRefinements;
