import React, { useEffect, useMemo, useState } from 'react';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { FacetProps } from './types';
import { useProductList } from '../../context';
import { FacetElement, FacetElementSelected, TreeFacetProps } from '../../types';

const TreeFacet: React.FC<FacetProps> = ({ attribute, loadingState }) => {
  const [showMore, setShowMore] = useState(true);
  const [displayShowMore, setDisplayShowMore] = useState(false);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { facetsConfiguration, refine } = useProductList();

  const facet = useMemo(() => facetsConfiguration[attribute] as TreeFacetProps, [facetsConfiguration, attribute]);

  const showLimit = 6;
  const displayedFacets = facet.elements.slice(0, showLimit);
  const displayedElements = showMore ? displayedFacets : facet.elements;
  const selectedElement = facet.selectedElements.find((element) => element.clusterLevel === 0);

  const elements = selectedElement ? [selectedElement] : displayedElements;

  useEffect(() => {
    setDisplayShowMore(facet.elements.length > displayedFacets.length);
  }, [facet.elements, displayedFacets]);

  const clickShowMore = () => {
    setShowMore(!showMore);
  };

  const handleOnChange = (element: FacetElement) => {
    if (loadingState) {
      loadingState.set({ [attribute]: true });
    }

    refine(attribute, element);
  };

  return (
    <div className="flex flex-col gap-16 pb-20 pt-8">
      {elements.map((element) => (
        <div key={element.text} className="flex w-full justify-start gap-8 pr-4">
          <Checkbox
            checked={element.selected === FacetElementSelected.FALSE ? false : true}
            disabled={element.selected === FacetElementSelected.IMPLICIT ? true : false}
            onChange={() => handleOnChange(element)}
          />

          <div className="flex w-full justify-between gap-8 text-14 leading-[17.5px]">
            <Typography className="text-neutral-4">{element.text}</Typography>
            <span className="text-neutral-3">{element.totalHits}</span>
          </div>
        </div>
      ))}

      {displayShowMore ? (
        <div onClick={clickShowMore}>
          <Typography className="cursor-pointer px-26 text-14 font-bold leading-[17.5px] text-base-accent-1">
            {showMore
              ? formatProductMessage({ id: 'showMore', defaultMessage: 'show more' })
              : formatProductMessage({ id: 'showLess', defaultMessage: 'show less' })}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

export default TreeFacet;
