import { useCallback } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

const useRefinementHelpers = () => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const resolveLabel = useCallback(
    (attribute: string, label: string) => {
      if (attribute.match(/status/)) {
        switch (label) {
          case 'sale':
            return formatProductMessage({ id: 'statusSaleFacet', defaultMessage: label });
          case 'available':
            return formatProductMessage({ id: 'statusAvailableFacet', defaultMessage: label });
          case 'new':
            return formatProductMessage({ id: 'statusNewFacet', defaultMessage: label });
          default:
            return label;
        }
      }

      return label;
    },
    [formatProductMessage],
  );

  return { resolveLabel };
};

export default useRefinementHelpers;
